<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{ $t('teaGarden.task_assign') }} {{ $t('globalTrans.search') }}</h4>
      </template>
      <template v-slot:searchBody>
        <b-row>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <b-form-group
              label-for="task_name"
            >
              <template v-slot:label>
                {{ $t('teaGarden.task_name') }}
              </template>
            <b-form-select
                plain
                v-model="search.task_id"
                :options="gdnTaskList"
                id="org_id"
                >
            <template v-slot:first>
                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
            </template>
            </b-form-select>
            </b-form-group>
          </b-col>
          <b-col sm="4">
            <b-button size="sm" variant="primary" class="mt-20" @click="searchData">
              <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
            </b-button>
          </b-col>
        </b-row>
      </template>
      <!-- search section end -->
    </card>

    <body-card>
      <!-- table section start -->
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('teaGarden.task_assign') }} {{ $t('globalTrans.list') }}</h4>
      </template>
      <template v-slot:headerAction>
        <router-link to="task-assign-form" class="btn-add"><i class="ri-add-fill"></i> {{ $t('globalTrans.add_new') }}</router-link>
      </template>
      <template v-slot:body>
        <b-overlay :show="loadingState">
          <b-row>
            <b-col sm="12">
                <div class="quick-filter-wrapper">
                  <div class="quick-filter-left">
                    <b-dropdown split split-variant="outline-secondary" size="sm" id="dropdown-form" :text="$t('globalTrans.column_visibility')" ref="dropdown">
                      <b-dropdown-form>
                        <div
                          class="form-group"
                          v-for="(field, index) in labelData"
                          :key="index"
                          >
                            <b-form-checkbox
                              :id="'checkbox-' + field.labels"
                              v-model="field.show"
                              :name="'checkbox-' + field.labels"
                              value=1
                              unchecked-value=0
                            >
                              {{ $t(field.labels)  }}
                            </b-form-checkbox>
                        </div>
                      </b-dropdown-form>
                    </b-dropdown>
                  </div>
                  <div class="quick-filter-right">
                    <b-form-group
                      :label="$t('menu.perpage')"
                      label-for="per-page-select"
                    >
                      <b-form-select
                        id="per-page-select"
                        v-model="search.limit"
                        :options="pageOptions"
                        size="sm"
                      ></b-form-select>
                    </b-form-group>
                  </div>
                </div>
              </b-col>
          </b-row>
          <b-row>
            <b-col md="12" class="table-responsive">
              <slot v-if ="columns.filter(item => item.show === '1').length > 0">
                <b-table :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" head-variant="primary" class="tg" :items="listData" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :fields="columns.filter(item => item.show === '1').sort((a, b) => { return a.order - b.order })">
                  <template v-slot:cell(serial)="data">
                    {{ $n(data.item.serial + pagination.slOffset) }}
                  </template>
                  <template v-slot:cell(task_year)="data">
                    <span>{{ $n(data.item.task_year, { useGrouping: false }) }}</span>
                  </template>
                  <template v-slot:cell(task_serial_no)="data">
                    <span>{{ $n(data.item.task_serial_no, { useGrouping: false }) }}</span>
                  </template>
                  <template v-slot:cell(garden_id)="data">
                    <span>{{ getTeaGardenName(data.item.garden_id) }}</span>
                  </template>
                  <template v-slot:cell(task_id)="data">
                    <span>{{ getTaskName(data.item.task_id) }}</span>
                  </template>
                  <template v-slot:cell(start_date)="data">
                    <span>{{ data.item.start_date | dateFormat }}</span>
                  </template>
                  <template v-slot:cell(end_date)="data">
                    <span>{{ data.item.end_date | dateFormat }}</span>
                  </template>
                  <template v-slot:cell(status)="data">
                      <span class="badge badge-warning" v-if="data.item.status == 1">{{$t('task_activity_monitoring.assign')}}</span>
                      <span class="badge badge-success" v-else>{{$t('manage_information.done')}}</span>
                  </template>
                  <template v-slot:cell(action)="data">
<!--                    <b-button v-b-modal.modal-form variant=" iq-bg-success mr-1" size="sm" @click="editMethodInParent(data.item, 1)" class="action-btn edit" :title="$t('globalTrans.edit')"><i class="ri-pencil-fill"></i></b-button>-->
                    <template v-if="data.item.task.is_individual">
                      <router-link variant=" iq-bg-success mr-1" size="sm" class="moc-edit-btn moc-action-btn action-btn edit" :to="`task-assign-form?id=${data.item.id}`" v-if="data.item.status === 1 && !data.item.achievements.length" :title="$t('globalTrans.edit')">
                        <i class="ri-pencil-fill"></i>
                      </router-link>
                    </template>
                    <template v-else>
                      <router-link variant=" iq-bg-success mr-1" size="sm" class="moc-edit-btn moc-action-btn action-btn edit" :to="`task-assign-form?id=${data.item.id}`" v-if="data.item.status === 1" :title="$t('globalTrans.edit')">
                        <i class="ri-pencil-fill"></i>
                      </router-link>
                    </template>
                    <router-link class="moc-action-btn moc-view-btn" :to="'task-assign-view/'+data.item.id" :title="$t('globalTrans.view')">
                      <i class="ri-eye-line"></i>
                    </router-link>
                    <router-link class="moc-action-btn btn-success" :to="'task-assign-achievement/'+data.item.id" :title="$t('teaGarden.achievement')" v-if="data.item.status === 1 && (data.item.target > 0) && !data.item.task.is_individual">
                      <i class="ri-goblet-line"></i>
                    </router-link>
                    <router-link class="moc-action-btn btn-success" :to="'task-assign-achievement-individual/'+data.item.id" :title="$t('teaGarden.achievement')" v-if="data.item.status === 1 && (data.item.target > 0) && data.item.task.is_individual">
                      <i class="ri-goblet-line"></i>
                    </router-link>
                    <router-link class="moc-action-btn btn-primary" :to="'task-assign-achievement-individual-list/'+data.item.id" :title="$t('teaGarden.achievement') + ' ' + $t('globalTrans.list')" v-if="data.item.status === 1 && (data.item.target > 0) && data.item.achievements.length" target="_blank">
                      <i class="ri-list-unordered"></i>
                    </router-link>
                    <b-button class="moc-action-btn moc-assign-btn" :title="$t('globalTrans.approve')" @click="approve(data.item)" v-if="data.item.status === 1 && (!data.item.target)">
                      <i class="ri-shield-check-line"></i>
                    </b-button>
                  </template>
                </b-table>
              </slot>
              <div class="pagination-wrapper mt-3" v-if="columns.filter(item => item.show === '1').length > 0">
                <b-pagination
                  v-model="pagination.currentPage"
                  :perPage="search.limit"
                  :total-rows="pagination.totalRows"
                  @input="searchData"
                />
               </div>
            </b-col>
          </b-row>
        </b-overlay>
      </template>
      <!-- table section end -->
    </body-card>
    <b-modal id="modal-form" size="lg" :title="formTitle" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Form :id="editItemId" :key="editItemId"/>
    </b-modal>
  </div>
</template>
<script>
import Form from './Form.vue'
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { taskAssignList, taskAssignApprove } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/list'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Form
  },
  data () {
    return {
      teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
      sortBy: '',
      sortDirection: 'desc',
      sortDesc: true,
      search: {
        task_id: 0,
        limit: 20
      },
      labelData: [
        { labels: 'globalTrans.sl_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '7%' } },
        { labels: 'teaGarden.task_year', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: 'auto' } },
        { labels: 'teaGarden.task_serial_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: 'auto' } },
        { labels: 'teaGardenService.tea_garden_name', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 4, thStyle: { width: 'auto' } },
        { labels: 'teaGarden.task_name', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 5, thStyle: { width: 'auto' } },
        { labels: 'globalTrans.start_date', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 6, thStyle: { width: 'auto' } },
        { labels: 'globalTrans.end_date', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 7, thStyle: { width: 'auto' } },
        { labels: 'globalTrans.status', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 8, thStyle: { width: 'auto' } },
        { labels: 'globalTrans.action', class: 'text-center', show: '1', order: 9, thStyle: { width: 'auto' } }
      ],
      actions: {
        edit: true,
        toogle: true,
        delete: false
      }
    }
  },
  created () {
    this.loadData()
  },
  watch: {
    'search.limit': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadData()
      }
    }
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    },
    columns () {
      const labelData = this.labelData
      const labels = labelData.map((item, index) => {
        return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
      })
      let keys = []
      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'serial' },
          { key: 'task_year' },
          { key: 'task_serial_no' },
          { key: 'garden_id' },
          { key: 'task_id' },
          { key: 'start_date' },
          { key: 'end_date' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'serial' },
          { key: 'task_year' },
          { key: 'task_serial_no' },
          { key: 'garden_id' },
          { key: 'task_id' },
          { key: 'start_date' },
          { key: 'end_date' },
          { key: 'status' },
          { key: 'action' }
        ]
      }
      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    },
    pageOptions () {
      return this.$store.state.commonObj.pageOptions
    },
    formTitle () {
      return (this.editItemId === 0) ? this.$t('teaGarden.task_assign') + ' ' + this.$t('globalTrans.entry') : this.$t('teaGarden.task_assign') + ' ' + this.$t('globalTrans.modify')
    },
    gdnTaskList () {
      return this.$store.state.TeaGardenService.commonObj.gdnTaskList.filter(item => item.status === 1)
    }
  },
  methods: {
    searchData () {
      this.loadData()
    },
    async loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getData(teaGardenServiceBaseUrl, taskAssignList, params)
      if (result.success) {
        const listData = result.data.data.map((item, index) => {
          return Object.assign({}, item, { serial: index })
        })
        this.$store.dispatch('setList', listData)
        this.paginationData(result.data, this.search.limit)
      } else {
        this.$store.dispatch('setList', [])
        this.paginationData([])
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getTeaGardenName (id) {
      const obj = this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.find(item => item.value === parseInt(id))
      if (obj) {
        return this.currentLocale === 'en' ? obj.text_en : obj.text_bn
      }
    },
    getTaskName (id) {
      const obj = this.$store.state.TeaGardenService.commonObj.gdnTaskList.find(item => item.value === parseInt(id))
      if (obj) {
        return this.currentLocale === 'en' ? obj.text_en : obj.text_bn
      }
    },
    approve (item) {
      window.vm.$swal({
        title: window.vm.$t('globalTrans.approveConfirmMsg'),
        showCancelButton: true,
        confirmButtonText: window.vm.$t('globalTrans.yes'),
        cancelButtonText: window.vm.$t('globalTrans.no'),
        focusConfirm: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
          RestApi.deleteData(teaGardenServiceBaseUrl, `${taskAssignApprove}/${item.id}`).then(response => {
            if (response.success) {
              window.vm.$toast.success({
                title: window.vm.$t('globalTrans.success'),
                message: window.vm.$t('globalTrans.update_msg'),
                color: '#D6E09B'
              })
            } else {
              window.vm.$toast.error({
                title: 'Error',
                message: 'Operation failed! Please, try again.'
              })
            }
            this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
          })
        }
      })
    }
  }
}
</script>
